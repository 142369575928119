.App {
  text-align: center;
}

.home {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}

.about {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100vw;
    height: auto;
}

.university {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
}

.experience {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
}

.experience p {
    line-height: 2rem;
}

.experience img {
    margin-top: 1rem;
    width: 25vw;
    height: auto;
    border-style: solid;
    border-radius: 1rem;
    border-width: 2px;
    border-color: aqua;
    align-self: center;
}

.project-page {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
}

.project-page p {
    line-height: 2rem;
}

.project-page a {
    text-decoration: underline;
    color: white;
}

.trivia {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    width: 100%;
    height: 100%;
    overflow: hidden;
}

.trivia-img {
    align-self: center;
    width: 1rem;
    height: auto;
    margin-bottom: 0.5rem;
}

.home img {
  margin-top: 1rem;
  width: 25vw;
  height: auto;
  border-style: solid;
  border-radius: 1.5rem;
  border-width: 2px;
  border-color: aqua;
}

.home h2 {
  font-size: 2rem;
}

.home p {
  font-size: 1rem;
}

.about .my-pic {
    margin-top: 1rem;
    width: 15vw;
    height: auto;
    border-style: solid;
    border-radius: 20rem;
    border-width: 2px;
    border-color: aqua;
    transition: animation 0.5s ease-in-out;
}

.about .my-pic:hover {
    animation: spin 1.5s linear infinite;
}

.about img {
    margin-top: 1rem;
    width: 30vw;
    height: auto;
    border-style: solid;
    border-radius: 1rem;
    border-width: 2px;
    border-color: aqua;
    align-self: center;
}

.university img {
    margin-top: 1rem;
    width: 30vw;
    height: auto;
    border-style: solid;
    border-radius: 1rem;
    border-width: 2px;
    border-color: aqua;
    align-self: center;
}

.university p {
    line-height: 2rem;
}

.university li {
    line-height: 2rem;
}

.about p {
    line-height: 2rem;
}

.projects {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
}

.projects li {
    line-height: 2rem;
}

.projects a {
    text-decoration: none;
    color: white;
}

.projects img {
    width: 30vw;
    height: auto;
    border-style: solid;
    border-radius: 1rem;
    border-color: white;
    border-width: 3px;
}

.project-image {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
}

.project-video {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    margin-top: 1rem;
}

.project-page img {
    width: 30vw;
    height: auto;
    border-style: solid;
    border-radius: 1rem;
    border-color: aqua;
    border-width: 3px;
}

.project-page li {
    line-height: 2rem;
}

.menu {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background: white;
    color: black;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    z-index: 1100;
    opacity: 0.9;

}

.nav-close-button {
    width: 4.5rem;
    height: auto;
    marginBottom: 1rem;
    transition: transform 0.3s ease;
    transform: scale(1);
}

.nav-close-button:hover {
    transform: scale(0.7)
}

.blog-img {
    width: 18vw;
    height: 32vh;
    align-self: center;
    margin-top: 1rem;
    border-radius: 2rem;
    object-fit: cover;
}

.pagination {
    margin-bottom: 2rem;
}

.blog-page {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
}

.blog-loading {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
}

.blog-header-textbox {
    max-width: 30%;
    margin: 2rem 2rem;
}

.blog-header-img {
    width: 20%;
    height: 40vh;
    margin: 2rem 2rem;
    object-fit: cover;
}

@media (max-width: 768px) {
    .home img {
        width: 50vw;
    }

    .about .my-pic {
        width: 30vw;
    }

    .about img {
        width: 60vw;
    }

    .university img {
        width: 60vw;
    }

    .projects a {
        font-size: 1.5rem;
    }

    .projects img {
        width: 60vw;
    }

    .projects li {
        font-size: 1rem;
        line-height: 1.5rem;
        margin-bottom: 0.5rem;
    }

    .project-page img {
        width: 80vw;
    }

    .experience img {
        width: 60vw;
    }

    .blog-img {
        width: 50vw;
        height: 35vh;
    }

    .pagination {
        margin-top: -3rem;
    }

    .blog-header-textbox {
        max-width: 80%;
    }

    .blog-header-img {
        width: 70%;
        height: 40vh;
    }
}

@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}
